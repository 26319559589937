export const mapLocaleToMeaningfulFormat = (locale: string) => {
  switch (locale) {
    default:
      return {
        shipToAddress: 'Ship to Address',
        items: 'Items',
        remove: 'Remove',
        edit: 'Edit',
        saveForLater: 'Save for later',
        alertMessage:
          'There are 1 or more items not in stock in you cart. Please remove them or update qty to proceed to checkout',
        shippingOptions: 'Shipping Options:',
        moveToWishList: 'Move to Wish List',
        addToCart: 'Add to Cart',
        pickUpStore: 'Pick Up In Store - Free',
        name: 'Name',
        inStock: 'In Stock',
        electronicDelivery: 'Electronic Delivery',
        outOfStock: 'Out Of Stock',
        standardShipping: 'Standard Shipping',
        expeditedShipping: 'Expedited Shipping',
        standardShippingId: 'standardShipping',
        expeditedShippingId: 'expeditedShipping',
        shippingMethod: 'shipping_method',
        freeStandardShipping: 'Standard Shipping - Free',
        home: 'Home',
        office: 'Office',
        apoAccepted: 'APO/FPO also accepted',
        mustDifferentFromUsername: 'Must be different from username',
        mustNotContainSpaces: 'Must not contain spaces',
        minimumPasswordLength: 'Minimum password length: 6',
        maximumPasswordLength: 'Maximum password length: 15',
        minimumUpperCaseCharacter: 'Minimum uppercase character (A-Z): 1',
        minimumDigit: 'Minimum digit(s) from 0-9: 1',
        allowSpecialCharacters: ' Allow special characters',
      };
  }
};
